<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Create a new survey</h1>
        <app-look-up-customer
            v-if="survey.customer === 0"
            @populateCustomer="populateCustomer" />
        <div class="mt-3">
          <router-link
              v-if="survey.customer === 0"
              to="/customers/create">
            <v-btn small>Add New Customer</v-btn>
          </router-link>
        </div>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <h4>Customer</h4>
            <p v-if="survey.customer.name">{{ survey.customer.name }}</p>
            <h4>Customer address</h4>
            <span v-if="survey.customer.address_1">{{ survey.customer.address_1 }}</span>
            <span v-if="survey.customer.address_2"><br />{{ survey.customer.address_2 }}</span>
            <span v-if="survey.customer.address_3"><br />{{ survey.customer.address_3 }}</span>
            <span v-if="survey.customer.address_4"><br />{{ survey.customer.address_4 }}</span>
            <span v-if="survey.customer.address_5"><br />{{ survey.customer.address_5 }}</span>
            <span v-if="survey.customer.postcode"><br />{{ survey.customer.postcode }}</span>
            <h4 class="pt-4 pb-0">Customer telephone</h4>
            <span v-if="survey.customer.tel">{{ survey.customer.tel }}</span>
            <h4 class="pt-4 pb-0" v-if="survey.customer.notes">Notes</h4>
            <p v-if="survey.customer.notes">{{ survey.customer.notes}}</p>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <v-btn
                small
                @click="survey.customer = 0">
              Change Customer
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
                v-model="survey.customer_reference"
                label="Customer reference"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0 && addNewContact === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
                :items="contacts"
                v-model="survey.contact_id"
                item-text="name"
                item-value="id"
                label="Contact"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0 && addNewContact === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn
                @click="addNewContact = true"
                small>Add New Contact</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0 && addNewContact === true">
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.first_name" label="First Name"></v-text-field>
          </v-col>
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.last_name" label="Last Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.email" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.tel" label="Tel"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn small @click="saveNewContact">Save contact</v-btn>
            &nbsp;
            <v-btn small @click="cancelNewContact">Cancel</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field  label="Contact number" v-model="survey.contact_number" />
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-textarea  label="Address" v-model="survey.address" />
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-textarea  label="Booking notes" v-model="survey.booking_notes" />
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <template>
              <v-date-picker
                  v-if="!date"
                  v-model="date"
                  show-adjacent-months
                  no-title
                  width="100%"
              ></v-date-picker>
            </template>
            <v-text-field
                v-if="date"
                :value="prettyDate"
                append-icon="mdi-close"
                @click:append="resetDate"
                label="Survey date">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-checkbox label="Internal" v-model="survey.internal"></v-checkbox>
            <v-checkbox label="External" v-model="survey.external"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
                :items="users"
                v-model="survey.requested_by"
                item-text="text"
                item-value="value"
                label="Requested by"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
                :items="users"
                v-model="survey.surveyed_by"
                item-text="text"
                item-value="value"
                label="To be surveyed by"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
                :items="vehicles"
                v-model="survey.vehicle"
                item-text="text"
                item-value="value"
                label="Vehicle"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="survey.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn @click="saveSurvey">Save</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
        v-model="showMessage"
        max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="orange darken-2"
              text
              @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LookUpCustomer from '@/components/Quotations/LookupCustomer.vue';
import axios from '../../axios';
import { format, parseISO } from 'date-fns';

export default {
  name: 'CreateQuotation',
  components: {
    appLookUpCustomer: LookUpCustomer,
  },
  data() {
    return {
      customer: {},
      survey: {
        booking_notes: '',
        contact_number: '',
        customer: 0,
        date: null,
        address: '',
      },
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      prettyDate: format(parseISO((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), 'EEEE, MMMM do yyyy'),
      contacts: [],
      message: '',
      showMessage: false,
      addNewContact: false,
      newContact: {
        first_name: '',
        last_name: '',
        email: '',
        tel: '',
      },
      users: [],
      vehicles: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    date() {
      this.prettyDate = format(parseISO(this.date), 'EEEE, MMMM do yyyy');
      this.quotation.date = this.date;
    },
  },
  methods: {
    cancelNewContact() {
      this.addNewContact = false;
      this.newContact = null;
    },
    getUsers() {
      axios.get(`/users/getSurveyors.json?token=${this.token}`)
          .then((response) => {
            this.users = response.data.users;
          });
    },
    getVehicles() {
      axios.get(`/vehicles/getSelectList.json?token=${this.token}`)
          .then((response) => {
            this.vehicles = response.data.vehicles;
          });
    },
    saveNewContact() {
      const postData = {};
      postData.customerId = this.survey.customer.id;
      postData.contact = this.newContact;
      axios.post(`/contacts/addFromQuote.json?token=${this.token}`, postData)
          .then((response) => {
            this.survey.contact_id = response.data.contact.id;
            this.survey.siteContact = response.data.contact.full_name;
            this.survey.siteTel = response.data.contact.tel;
            this.survey.siteEmail = response.data.contact.email;
            const cont = {};
            cont.id = response.data.contact.id;
            cont.name = response.data.contact.full_name;
            cont.email = response.data.contact.email;
            cont.tel = response.data.contact.tel;
            this.contacts.push(cont)
            this.addNewContact = false;
            this.newContact = {};
          })
          .catch((error) => {
            console.log(error);
          });
    },
    populateCustomer(customer) {
      axios.get(`/customers/get/${customer.id}.json?token=${this.token}`)
          .then((response) => {
            this.foundCustomers = [];
            this.customer = '';
            this.survey.customer = response.data.customer;
            this.contacts = response.data.contacts;
            this.sites = response.data.sites;
          });
    },
    saveSurvey() {
      const postData = {};
      postData.survey = this.survey;
      postData.date = this.date;
      axios.post(`/siteSurveys/create.json?token=${this.token}&user=${this.userid}`, postData)
          .then((response) => {
            this.message = response.data.message;
            this.showMessage = true;
          });
    },
    checkCustomer() {
      if (this.$route.params.customer) {
        const customerId = this.$route.params.customer;
        axios.get(`/customers/getSingleForQuote/${customerId}.json?token=${this.token}`)
            .then((response) => {
              this.quotation.customer = response.data.customer;
              this.contacts = response.data.contacts;
              this.sites = response.data.sites;
            });
      }
    },
    resetDate() {
      this.prettyDate = null;
      this.date = null;
      this.quotation.date = null;
    },
  },
  mounted() {
    this.checkCustomer();
    this.getUsers();
    this.getVehicles();
  }
}
</script>
